<template>
  <div class="simple-widget">
    <CCard body-wrapper>
      <div class="header">
        <slot name="header" />
      </div>
      <div class="pt-2">
        <slot />
      </div>
    </CCard>
  </div>
</template>

<script>
/**
 * Simple widget to display header and content.
 *
 * Usage:
 *
 * <RWidgetSimple>
 *   <template #header>
 *     Example Header
 *   </template>
 *   <div>
 *     Example Content
 *   </div>
 * </RWidgetSimple>
 */
export default {
  name: 'RWidgetSimple'
}
</script>

<style lang="scss" scoped>
.simple-widget {
  .card {
    border: 1px solid $gray-100;
    .card-body {
      padding: 1rem 1rem;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
</style>
