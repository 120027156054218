<template>
  <CRow class="r-widget-progress">
    <CCol>
      <CRow :gutters="false">
        <CCol col="8" class="text">
          {{ text }}
        </CCol>
        <CCol col="4" class="values">
          <span class="value">{{ value }}</span>
          <span class="pl-1 percent">({{ percent }}%)</span>
        </CCol>
      </CRow>
      <CRow :gutters="false">
        <CCol class="subtext">
          {{ subText }}
        </CCol>
      </CRow>
      <CRow class="pt-2">
        <CCol>
          <CProgress
            v-bind="$props"
            :striped="false"
            :show-percentage="false"
            :show-value="false"
          />
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
/**
 * Simple widget to display a progress bar.
 *
 * Usage:
 *
 * <RWidgetProgress
 *   text="Example Text"
 *   color="info"
 *   :value="25"
 *   :max="100"
 * />
 */
export default {
  name: 'RWidgetProgress',
  props: {
    text: {
      type: String,
      default: ''
    },
    subText: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: undefined
    },
    height: {
      type: String,
      default: '10px'
    },
    value: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    }
  },
  computed: {
    percent () {
      if (this.max > 0) {
        return Math.round((this.value / this.max) * 100)
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.r-widget-progress {
  .text {
    font-weight: bold;
  }
  .values {
    text-align: right;
    .value {
      font-weight: bold;
    }
    .percent {
      font-size: 0.8rem;
      font-weight: 600;
      color: $gray-400;
    }
  }
  .subtext {
    color: $gray-400;
  }
}
</style>
