<template>
  <div class="status-widget">
    <CIcon name="md-circle" :class="status.toLowerCase()" height="10" />
    <div>
      {{ status }}
      <div v-if="date" class="date">
        {{ date }}
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Widget to display a property's status.
 */
const STATUSES = [
  'inactive', 'active', 'trial', 'pre-trial', 'onboarding',
  'offboarding', 'pending', 'invalid', 'auto-created'
]

export default {
  name: 'RWidgetStatus',
  props: {
    status: {
      type: String,
      required: true,
      validator: value => {
        return STATUSES.includes(value.toLowerCase())
      }
    },
    date: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.status-widget {
  display: flex;
  align-items: baseline;
  color: $dark;
  text-transform: capitalize;
  .date {
    color: $gray-700;
    font-size: 0.8em;
  }
  .c-icon {
    margin: 10px;
    &.inactive {
      color: $secondary-25;
    }
    &.active {
      color: $success;
    }
    &.trial {
      color: $primary;
    }
    &.pre-trial {
      color: $gray-700;
    }
    &.onboarding {
      color: $info;
    }
    &.offboarding {
      color: $danger;
    }
    &.pending {
      color: $warning;
    }
    &.auto-created {
      color: $info;
    }
  }
}
</style>
