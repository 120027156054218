<template>
  <div>
    <CRow>
      <CCol>
        <RDataTable
          :items="tableData"
          :fields="columns"
        >
          <template #human_name="{ item }">
            <td>
              <CLink :to="getLocation(item)">
                {{ item.human_name }}
              </CLink>
            </td>
          </template>
          <template #status="{ item }">
            <td>
              <RWidgetStatus
                :status="item.status"
              />
            </td>
          </template>
        </RDataTable>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { RDataTable } from '@/components/tables'
import { RWidgetStatus } from '@/components/widgets'
import { deepCopy } from '@/utils'
import constants from '@/utils/constants'

const columns = [
  {
    key: 'human_name',
    label: 'Company Name',
    _style: 'width: 20%'
  },
  {
    key: 'active',
    label: 'Active Properties',
    _style: 'width: 10%'
  },
  {
    key: 'inactive',
    label: 'Inactive Properties',
    _style: 'width: 10%'
  },
  {
    key: 'salesperson__name',
    label: 'Salesperson',
    _style: 'width: 20%'
  },
  {
    key: 'status',
    _style: 'width: 10%'
  }
]
// Default ordering for the status column
const statusOrderMap = {
  [constants.rooof.Status.INVALID]: 0,
  [constants.rooof.Status.PENDING]: 1,
  [constants.rooof.Status.PRETRIAL]: 2,
  [constants.rooof.Status.TRIAL]: 3,
  [constants.rooof.Status.ONBOARDING]: 4,
  [constants.rooof.Status.ACTIVE]: 5,
  [constants.rooof.Status.OFFBOARDING]: 6,
  [constants.rooof.Status.INACTIVE]: 7
}

export default {
  name: 'CompanyTable',
  components: {
    RDataTable,
    RWidgetStatus
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      columns
    }
  },
  computed: {
    tableData () {
      // Map address values directly to row obj, allows automatic sort/filter
      return this.data.map(company => {
        // Make a copy to avoid mutating vuex state
        const companyCopy = deepCopy(company)
        companyCopy.salesperson__name = companyCopy.salesperson__name || ''
        return companyCopy
      }).sort((a, b) => {
        // Apply default sort: status, then human_name
        return (
          statusOrderMap[a.status] - statusOrderMap[b.status] ||
          a.human_name.localeCompare(b.human_name)
        )
      })
    }
  },
  methods: {
    /**
     * Generate the route location object for navigating to
     * the company performance report.
     *
     * @param {Object} row - table row item
     * @returns {Object}
     */
    getLocation (row) {
      return {
        name: 'PerformanceReport',
        params: { cid: row.id }
      }
    }
  }
}
</script>
