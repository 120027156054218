<template>
  <div id="company-list">
    <CRow>
      <CCol>
        <h2>
          Customers
          <span class="h4">({{ companies.length }})</span>
        </h2>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RTableFilterOptions :config="filterConfig" @change="handleFilterChange" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CompanyTable :data="filtered" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { RTableFilterOptions } from '@/components/tables'
import { capitalize } from '@/utils'

import CompanyTable from './CompanyTable'

export default {
  name: 'CompanyList',
  components: {
    CompanyTable,
    RTableFilterOptions
  },
  data () {
    return {
      companyNameFilter: '',
      salespersonFilter: [],
      statusFilter: []
    }
  },
  computed: {
    /**
     * Access company summary state from Vuex.
     *
     * @returns {Array}
     */
    companies () {
      return this.$store.state.rooof.companies
    },
    /**
     * Filter companies held in store.
     *
     * @returns {Array}
     */
    filtered () {
      return this.companies.filter(company => {
        let include = true
        // Check company contains correct statuses
        if (this.statusFilter.length > 0) {
          include &= this.statusFilter.includes(company.status)
        }
        // Assert company contains correct salespeople
        if (this.salespersonFilter.length > 0) {
          include &= this.salespersonFilter.includes(company.salesperson__name)
        }
        // Assert company human name contains correct substring
        if (this.companyNameFilter.length > 0) {
          include &= company.human_name.toLowerCase().includes(this.companyNameFilter.toLowerCase())
        }
        return include
      })
    },
    /**
     * Calculate filter options for filterConfig.
     *
     * @returns {Object}
     */
    filterOptions () {
      const status = new Set()
      const salespersonNames = new Set()
      const statusCounts = {}

      for (const company of this.companies) {
        if (company.salesperson__name !== null) {
          salespersonNames.add(company.salesperson__name)
        }
        status.add(company.status)
        statusCounts[company.status] = statusCounts[company.status] + 1 || 1
      }
      return {
        status: Array.from(status).sort().map(status => ({
          label: `${capitalize(status)} (${statusCounts[status]})`,
          value: status
        })),
        salesperson: Array.from(salespersonNames).sort().map(name => ({
          label: name,
          value: name
        }))
      }
    },
    /**
     * Config for RTableFilterOptions.
     *
     * @returns {Object}
     */
    filterConfig () {
      return {
        status: {
          type: 'multiselect',
          label: 'Status',
          options: this.filterOptions.status
        },
        salesperson: {
          type: 'multiselect',
          label: 'Salesperson',
          options: this.filterOptions.salesperson
        },
        companyName: {
          type: 'input',
          label: 'Company Name'
        }
      }
    }
  },
  methods: {
    /**
     * Updates search filters for companies.
     *
     * @param {Object} newFilters - filter selections from RTableFilterOptions
     */
    handleFilterChange (newFilters) {
      this.companyNameFilter = newFilters.companyName
      this.salespersonFilter = newFilters.salesperson
      this.statusFilter = newFilters.status
    }
  }
}
</script>
