<template>
  <div class="r-widget-info pb-3">
    <CRow>
      <CCol class="icon">
        <CIcon :name="icon" height="22" />
      </CCol>
      <CCol>
        <CRow class="pb-1">
          <CCol class="label">
            {{ label }}
          </CCol>
        </CRow>
        <CRow>
          <CCol class="value">
            <slot>
              {{ value || '(none)' }}
            </slot>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'RWidgetInfo',
  props: {
    icon: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.r-widget-info {
  color: $secondary;

  .icon {
    flex-grow: 0;
  }
  .label {
    font-size: 1rem;
    font-weight: bold;
  }
  .value {
    font-weight: 500;
    word-break: break-word;
  }
}
</style>
